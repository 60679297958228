<template>
  <div>
    <!--Basic Submission info-->
    <div class="d-inline-flex card text-center w-75">
      <div class="card-header">
        {{ title }}
      </div>
      <div class="card-body">
        <a :href="link" class="btn btn-primary" target="_blank">View Submission</a>
        <h5 class="card-title"> {{ format }}, {{ medium }}, {{ completion }}. Submission number {{ submittingUserSubmissionNumber }} by {{ submittingUserUsername }}</h5>
        <p class="card-text"> {{ tags }}</p>
      </div>
      <div class="card-footer text-muted">
        {{ createdAt }}
      </div>
    </div><br>

    <hr class="p-2 w-25">

    <!--Original Submission-->
    <div class="d-inline-flex card text-center w-75">
      <div class="card-header">
        Original comments:
      </div>
      <div class="card-body">
        <h5 class="card-title"> Focus: </h5>
        <p class="card-text"> {{ focus }}</p>
      </div>
      <div class="card-body">
        <h5 class="card-title"> Questions: </h5>
        <p class="card-text"> {{ questions }}</p>
      </div>
    </div>

    <hr class="p-2 w-25">

    <!--First Review-->
    <div class="card d-inline-flex text-center w-75" v-if="secondImpressions !== 'incomplete'">
      <div class="card-header">
        First Review:
      </div>
      <div class="card-body">
        <h5 class="card-title"> The reviewer's first impressions: </h5>
        <p class="card-text"> {{ firstImpressions }}</p>
      </div>
      <div class="card-body">
        <h5 class="card-title"> The reviewer's qualms, or issues: </h5>
        <p class="card-text"> {{ firstQualms }}</p>
      </div>
      <div class="card-body">
        <h5 class="card-title"> The reviewer's answers to the questions asked in the submission:</h5>
        <p class="card-text"> {{ firstAnswers }}</p>
      </div>
      <div class="card-body">
        <h5 class="card-title"> The reviewer's suggestions:</h5>
        <p class="card-text"> {{ firstSuggestions }}</p>
      </div>
      <div class="card-footer text-muted">
        Review number {{ firstReviewerFirstReviewNumber }} by user {{ firstReviewerUsername }}
      </div>
    </div>

    <hr class="p-2 w-25">

    <!--Second Review-->
    <div class="d-inline-flex card text-center w-75" v-if="secondImpressions !== 'incomplete'">
      <div class="card-header">
        Second Review:
      </div>
      <div class="card-body">
        <h5 class="card-title"> The reviewer's first impressions:</h5>
        <p class="card-text"> {{ secondImpressions }}</p>
      </div>
      <div class="card-body">
        <h5 class="card-title"> The second reviewer made these additions to what the first reviewer had to say:</h5>
        <p class="card-text"> {{ secondAdditions }}</p>
      </div>
      <div class="card-footer text-muted">
        Review number {{ secondReviewerSecondReviewNumber }} by user {{ secondReviewerUsername }}
      </div>
    </div>

    <div v-if="secondImpressions == 'incomplete'">
      <p class="words">Your submission is waiting to be given feedback. Check again in a while.</p>
    </div>

    <hr class="p-2 w-25">

  </div>
</template>

<script>
export default {
  name: 'FullSubmissionDisplay',
  props: {
    msg: String
  },
  data: () => ({
    decodedCompletion: '',
    decodedAgeRating: ''
  }),
  computed: {
    id () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.id
    },
    submittingUserUsername () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.submittingUserUsername
    },
    title () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.title
    },
    link () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.link
    },
    format () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.format
    },
    medium () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.medium
    },
    completion () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.completion
    },
    submittingUserSubmissionNumber () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.submittingUserSubmissionNumber
    },
    tags () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.tags
    },
    focus () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.focus
    },
    questions () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.questions
    },
    fan () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.fan
    },
    firstImpressions () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.firstImpressions
    },
    firstAnswers () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.firstAnswers
    },
    firstQualms () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.firstQualms
    },
    firstSuggestions () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.firstSuggestions
    },
    firstReviewerUsername () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.firstReviewerUsername
    },
    firstReviewerFirstReviewNumber () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.firstReviewerFirstReviewNumber
    },
    secondReviewerReviewStandard () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.secondReviewerReviewStandard
    },
    secondImpressions () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.secondImpressions
    },
    secondAdditions () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.secondAdditions
    },
    secondReviewerUsername () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.secondReviewerUsername
    },
    secondReviewerSecondReviewNumber () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.secondReviewerSecondReviewNumber
    },
    public () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.public
    },
    likes () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.likes
    },
    ageRating () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.ageRating
    },
    createdAt () {
      return this.$store.state.feedbackkerViewers.chosenSubmission.createdAt
    },
    decodeCompletion (completion) {
      if (this.completion === 0) {
        return 'Vague idea'
      }
      if (this.completion === 1) {
        return 'Initial Sketch or Planning'
      }
      if (this.completion === 2) {
        return 'Partial work-in-progress'
      }
      if (this.completion === 3) {
        return 'Majority work-in-progress'
      }
      if (this.completion === 4) {
        return 'Full-scale work-in-progress'
      }
      if (this.completion === 5) {
        return 'Partially edited/post-produced'
      }
      if (this.completion === 6) {
        return 'Believed complete'
      }
      if (this.completion === 7) {
        return 'Complete and published'
      }
      return this.completion
    },
    decodeAgeRating (ageRating) {
      if (this.ageRating === 0) {
        return 'Suitable for All Ages'
      }
      if (this.ageRating === 0) {
        return '13+'
      }
      if (this.ageRating === 0) {
        return '16+'
      }
      if (this.ageRating === 0) {
        return '18+'
      }
      if (this.ageRating === 0) {
        return 'NSFW'
      }
      return this.decodedAgeRating
    }
  },
  methods: {
    markAsRead () {
      if (this.submittingUserUsername === localStorage.getItem('atUsername')) {
        const submission = {
          username: this.submittingUserUsername,
          createdAt: this.createdAt
        }
        this.$store.dispatch('feedbackkerSubmissions/markAsRead', submission)
      }
    }
  },
  mounted () {
    this.markAsRead()
  }
}
</script>
<style scoped>
.words{
  color: white;
}
</style>
