<template>
<div>
  <div class="d-inline-flex">
  <!--Basic Submission info-->
    <div class="card text-center">
      <div class="card-header">
        <button type="submit" @click="loadFullView" class="btn btn-link"> {{ title }}</button>
      </div>
      <div class="card-body">
        <a :href="link" class="btn btn-primary" target="_blank">View Submitted Content</a>
        <h5 class="card-title"> {{ format }}, {{ medium }}, {{ decodeCompletion }}. Submission number {{ submittingUserSubmissionNumber }} by {{ submittingUserUsername }}</h5>
        <p class="card-text"> {{ tags }}</p>
        <p class="card-text"> Age rated: {{ decodeAgeRating }} </p>
      </div>
      <div class="card-footer text-muted">
        {{ createdAt }}
      </div>
    </div>
  </div>
  <hr class="p-2 w-25">
</div>
</template>

<script>
export default {
  props: {
    id: Number,
    submittingUserUsername: String,
    title: String,
    link: String,
    format: String,
    medium: String,
    completion: Number,
    submittingUserSubmissionNumber: Number,
    tags: String,
    focus: String,
    questions: String,
    fan: Number,
    firstImpressions: String,
    firstAnswers: String,
    firstQualms: String,
    firstSuggestions: String,
    firstReviewerUsername: String,
    firstReviewerFirstReviewNumber: Number,
    secondReviewerReviewStandard: Number,
    secondImpressions: String,
    secondAdditions: String,
    secondReviewerUsername: String,
    secondReviewerSecondReviewNumber: Number,
    public: Number,
    likes: Number,
    ageRating: Number,
    createdAt: String
  },
  data: () => ({
    decodedCompletion: '',
    decodedAgeRating: ''
  }),
  computed: {
    decodeCompletion (completion) {
      if (this.completion === 0) {
        return 'Vague idea'
      }
      if (this.completion === 1) {
        return 'Initial Sketch or Planning'
      }
      if (this.completion === 2) {
        return 'Partial work-in-progress'
      }
      if (this.completion === 3) {
        return 'Majority work-in-progress'
      }
      if (this.completion === 4) {
        return 'Full-scale work-in-progress'
      }
      if (this.completion === 5) {
        return 'Partially edited/post-produced'
      }
      if (this.completion === 6) {
        return 'Believed complete'
      }
      if (this.completion === 7) {
        return 'Complete and published'
      }
      return this.decodeCompletion
    },
    decodeAgeRating (ageRating) {
      if (this.ageRating === 0) {
        return 'Suitable for All Ages'
      }
      if (this.ageRating === 0) {
        return '13+'
      }
      if (this.ageRating === 0) {
        return '16+'
      }
      if (this.ageRating === 0) {
        return '18+'
      }
      if (this.ageRating === 0) {
        return 'NSFW'
      }
      return this.decodedAgeRating
    }
  },
  methods: {
    loadFullView () {
      this.$store.dispatch('feedbackkerViewers/displayFullView', this.$props)
    }
  }
}
</script>
