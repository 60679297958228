<template>
  <div>
    <FullSubmissionDisplay/>
  </div>
</template>

<script>
// @ is an alias to /src
import FullSubmissionDisplay from '@/components/FullSubmissionDisplay.vue'

export default {
  name: 'DisplaySubmission',
  components: {
    FullSubmissionDisplay
  },
  data: () => ({

  })
}

</script>
