<template>
  <div>
    <ShortSubmissionDisplay
    v-for="submission in submissions"
    v-bind:item="submission"
    v-bind:key="submission.id"
    v-bind:title="submission.title"
    v-bind:link="submission.link"
    v-bind:submittingUserUsername="submission.submitting_user_username"
    v-bind:format="submission.format"
    v-bind:medium="submission.medium"
    v-bind:completion="submission.completion"
    v-bind:submittingUserSubmissionNumber="submission.submitting_user_submission_number"
    v-bind:tags="submission.tags"
    v-bind:focus="submission.focus"
    v-bind:questions="submission.questions"
    v-bind:fan="submission.fan"
    v-bind:firstImpressions="submission.first_impressions"
    v-bind:firstAnswers="submission.first_answers"
    v-bind:firstQualms="submission.first_qualms"
    v-bind:firstSuggestions="submission.first_suggestions"
    v-bind:firstReviewerUsername="submission.first_reviewer_username"
    v-bind:firstReviewerFirstReviewNumber="submission.first_reviewer_first_review_number"
    v-bind:secondReviewerReviewStandard="submission.second_reviewer_review_standard"
    v-bind:secondImpressions="submission.second_impressions"
    v-bind:secondAdditions="submission.second_additions"
    v-bind:secondReviewerUsername="submission.second_reviewer_username"
    v-bind:secondReviewerSecondReviewNumber="submission.second_reviewer_second_review_number"
    v-bind:public="submission.public"
    v-bind:likes="submission.likes"
    v-bind:ageRating="submission.age_rating"
    v-bind:createdAt="submission.created_at"> </ShortSubmissionDisplay>

  </div>
</template>

<script>
// @ is an alias to /src
import ShortSubmissionDisplay from '@/components/ShortSubmissionDisplay.vue'

export default {
  name: 'FirstReviews',
  components: {
    ShortSubmissionDisplay
  },
  data: () => ({

  }),
  computed: {
    submissions () {
      return this.$store.state.feedbackkerViewers.firstReviews.data
    }
  },
  methods: {
    getSubmissionsFromApi () {
      this.$store.dispatch('feedbackkerViewers/getFirstReviews').then(() => {
        this.retrieveSubmissionsFromStore()
        console.log(this.retrieveSubmissionsFromStore)
      })
    }
  },
  mounted () {
    this.getSubmissionsFromApi()
  }
}
</script>
