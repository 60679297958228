<template>
  <div>
    <div class="form-horizontal">
    <!--Form Begins -->
    <fieldset>
    <!-- Form Name -->
    <div class="d-inline-flex card text-center w-75">
      <legend class="card-header">Give Constructive Criticism</legend>
      <h3 class="p-3">'{{ retrieved.frTitle }}'</h3>
      <p class="text-muted">{{ retrieved.frFormat }}, {{ retrieved.frMedium }}, by user {{ retrieved.frSubmittingUserUsername }}. {{ retrieved.frAgeRating }}.</p>
      <div class="d-inline-flex justify-content-center">
        <button type="button" id="submission" name="link" class="justify-content-center btn btn-primary" value="submission"><a :href="retrieved.frLink" target="_blank">View submission</a></button>
      </div>
      <p> Tagged as: <br>{{retrieved.frTags}} </p>
      <p v-if="retrieved.frFan"> It is fan content.</p>
      <p v-else> It is not fan content.</p>
      <p class="card-footer text-muted">If anything seems amiss with this submission, please contact site management.</p>
    </div>
    <hr class="p-2 w-25">
    <div class="d-inline-flex card text-center w-75">
      <h4 class="card-header p-2">Once you've had a look at the submission</h4>
      <h5 class="p-2">{{ retrieved.frSubmittingUserUsername }} wants to focus on:</h5>
      <p>{{ retrieved.frFocus }}</p>
      <h5>They were hoping you could answer these questions:</h5>
      <p>{{ retrieved.frQuestions }}</p>
      <p class="card-footer text-muted">This is submission number {{ retrieved.frSubmissionNumber }} by this user.
      <br><i v-if="retrieved.frSubmissionNumber<4">Please be especially considerate to new users!</i></p>
    </div>

    <hr class="p-2 w-25">

    <div class="d-inline-flex card text-center w-75">
      <!-- First Impressions -->
      <div class="form-group p-2">
        <label class="control-label card-header p-2 " for="firstImpressions">What were your first impressions of the user's submission?</label>
        <p class="text-muted p-2">This is your opportunity to praise the submission for its successes! Be sure to give detailed, sincere and specific compliments.</p>
        <div class="justify-content-center">
          <textarea class="form-control" id="firstImpressions" rows="10" name="firstImpressions" maxlength="2040" v-model="contribution.frFirstImpressions"></textarea>
        </div>
      <!--<p class="card-footer text-muted"><small></small></p>-->
      </div>
    </div>

  <hr class="p-2 w-25">

    <div class="d-inline-flex card text-center w-75">
      <!-- Answers -->
      <div class="form-group p-2">
        <label class="control-label card-header p-2" for="answers">Answer the user's questions as you're able to here:</label>
        <p class="text-muted p-2">If you don't understand, or can't answer everything, don't worry! But please, acknowledge
          this, so that the second-phase reviewer and recipient know that you've done your best work.</p>
        <div class="justify-content-center">
          <textarea class="form-control" id="answers" name="answers" rows="10" maxlength="2040" v-model="contribution.frAnswers"></textarea>
        </div>
      </div>
    </div>

    <hr class="p-2 w-25">

    <div class="d-inline-flex card text-center w-75">
      <!-- Qualms -->
      <div class="form-group p-2">
        <label class="control-label card-header p-2" for="qualms">Highlight any "qualms", or "areas for improvement" that stood out to you.</label>
        <p class="text-muted p-2">This is the hard bit, and by far the most valuable and important.
         <br><i>The power is now yours to be extremely helpful for someone. Be kind, considerate, and above all specific.</i></p>
        <div class="justify-content-center">
          <textarea class="form-control" id="qualms" name="qualms" rows="10" maxlength="2040" v-model="contribution.frQualms"></textarea>
        </div>

      </div>
    </div>

    <hr class="p-2 w-25">

    <div class="d-inline-flex card text-center w-75">
      <!-- Suggestions -->
      <div class="form-group p-2">
        <label class="control-label card-header p-2" for="qualms">Share any suggestions here: recommend reading, viewing, or useful tips for the future!</label>
        <p class="text-muted p-2">This is the fun bit. Revisit your first impressions, or suggest something useful and enjoyable that
          you have benefitted from yourself. Touch the heart of your fellow creative with something that will see them enthused to carry
          on their work!
        </p>
        <div>
          <textarea class="form-control" id="qualms" name="qualms" rows="10" maxlength="2040" v-model="contribution.frSuggestions"></textarea>
        </div>
      </div>
    </div>

    <hr class="p-2 w-25">

      <div v-if="retrieved.srExists === 'false'" class="d-inline-flex p-2 card text-center w-75">
            <div class=" justify-content-center">
              <h4>A suitable submission in need of second-phase review could not be found.</h4>
              <p>Please proceed to submit the first-phase review above as normal.</p>
              <p>A feedback will be awarded to you when that review passes its second-phase review.</p>
            </div>
      </div>
      <div v-if="retrieved.srExists === 'true'">
        <div>
          <hr class="p-2 w-25">
          <div class="d-inline-flex card text-center w-75">
            <legend class="card-header">Next...</legend>
            <h3 class="p-3">'{{ retrieved.srTitle }}'</h3>
            <p class="text-muted">{{ retrieved.srFormat }}, {{ retrieved.srMedium }} by user {{ retrieved.srSubmittingUserUsername }}, reviewed by user {{ retrieved.srFirstReviewerUsername }}. {{ retrieved.srAgeRating }}.</p>
            <div class="d-inline-flex justify-content-center">
              <button type="button" id="submission" name="link" class="btn btn-primary" value="submission"><a :href="retrieved.srLink" target="_blank">View Submission</a></button>
            </div>
            <p> Tagged as: {{retrieved.srTags}} </p>
            <p v-if="retrieved.srFan"> It is fan content.</p>
            <p v-else> It is not fan content.</p>
            <p class="card-footer text-muted">If anything seems amiss with this submission, please contact site management.</p>
          </div>
        </div>

          <hr class="p-2 w-25">

          <div class="d-inline-flex card text-center w-75">
            <h4 class="card-header p-2">Once you've had a look at the submission</h4>
            <h3>{{ retrieved.srSubmittingUserUsername }} wants to focus on:</h3>
            <p>{{ retrieved.srFocus }}</p>
            <h5>They asked these questions:</h5>
            <p>{{ retrieved.srQuestions }}</p>
            <p class="card-footer text-muted">This is submission number {{ retrieved.srSubmissionNumber }} by this user.
            <br><i v-if="retrieved.frSubmissionNumber<4">Please be especially considerate to new users!</i></p>
          </div>

          <hr class="p-2 w-25">

          <div class="d-inline-flex card text-center w-75">
            <h3 class="card-header p-2">User {{ retrieved.srFirstReviewerUsername }} had this to say</h3>
            <p> They have completed {{ retrieved.srFirstReviewerFirstReviewNumber }} reviews to date. </p>
            <h5>Here's how they described their first impressions:</h5>
            <p>{{ retrieved.srFirstImpressions }}</p>
            <h5>Here's how they answered {{ retrieved.srSubmittingUserUsername }}'s questions:</h5>
            <p>{{ retrieved.srQuestions }}</p>
            <h5>They raised these qualms:</h5>
            <p>{{ retrieved.srQualms }}</p>
            <h5>And finally, they made these suggestions:</h5>
            <p>{{ retrieved.srSuggestions }}</p>
          </div>

          <hr class="p-2 w-25">

        <div class="d-inline-flex card text-center w-75">
          <!-- Select Tactfulness -->
          <h3 class="card-header p-2">Tactfulness</h3>
          <p class="control-label text-muted p-2" for="Tactful">Score this review on its tactfulness. Give 0 for a review that is
            clearly deliberately hurtful, and 5 for a review that is written kindly and thoughtfully, and you think is highly
            unlikely to cause hurt or distress.
          </p>
          <div class="d-inline-flex p-4">
            <select id="Tactful" name="Tactful" class="form-control min-vw-25" v-model.number="contribution.srTactful" type="number">
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
        </div>

        <hr class="p-2 w-25">

        <div class="d-inline-flex card text-center w-75">
          <!-- Select Value -->
          <h3 class="card-header p-2">Value</h3>
          <label class="control-label p-2" for="Valuable">Score this review on how valuable you believe it can be.
            <br>Purely gushing reviews, hurtful reviews, or reviews that seem to be written with the wrong motives in mind
            should all get a low score, whereas reviews that effectively balance critical and positive content, are specific,
            and justify their decisions convincingly should be scored highly.
          </label>
          <div class="d-inline-flex p-4">
            <select id="Valuable" name="Valuable" class="form-control justify-content-center" v-model.number="contribution.srValuable" type="number">
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
        </div>

        <hr class="p-2 w-25">

        <div class="d-inline-flex card text-center w-75">
          <h3 class="card-header p-2">Writing Standard</h3>
          <label class="control-label p-2" for="wellWritten">Score this review on its writing. Specific, readable, logical and
            persuasive points all warrant a high score. Spelling and grammar errors, lack of specificity or readability all
            warrant a low score.
          </label>
          <div class="d-inline-flex p-4">
          <select id="wellWritten" name="wellWritten" class="form-control d-inline-flex" v-model.number="contribution.srWellWritten" type="number">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          </div>
        </div>

        <hr class="p-2 w-25">

        <!-- Second First Impressions -->
        <div class="d-inline-flex card text-center w-75">
          <h3 class="card-header p-2">First Impressions</h3>
          <label class="control-label p-3" for="secondFirstImpressions">What were your first impressions of {{ retrieved.srSubmittingUserUsername }}'s submission?</label>
          <textarea class="form-control p-4" rows="5" id="secondFirstImpressions" name="secondFirstImpressions" maxlength="2040" v-model="contribution.srSecondImpressions"></textarea>
        </div>

        <hr class="p-2 w-25">

        <!-- Second Reviewer Additions -->
        <div class="d-inline-flex card text-center w-75">
          <h3 class="card-header p-2">Any Additions?</h3>
          <label class="control-label p-3" for="srAdditions">Are there any additions you'd like to make to {{ retrieved.srFirstReviewerUsername }}'s review?</label>
          <textarea class="form-control p-4" rows="5" id="srAdditions" name="srAdditions" maxlength="2040" v-model="contribution.srAdditions"></textarea>
        </div>
      </div>

        <hr class="p-2 w-25">

        <!-- Button -->
        <div class="form-group p-2">
          <label class="control-label p-3" id="submitLabel" for="submit">A feedback will be awarded when your first-phase review is read by its recipient.</label>
            <button type="submit" id="submit" name="submit" class="btn btn-primary justify-content-center" value="Submit" @click="contributeFeedback">Submit Feedback</button>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GiveForm',
  props: {
    msg: String
  },
  methods: {
    contributeFeedback () {
      const contributionData = {
        frFirstImpressions: this.contribution.frFirstImpressions,
        frAnswers: this.contribution.frAnswers,
        frQualms: this.contribution.frQualms,
        frSuggestions: this.contribution.frSuggestions,

        frUsername: localStorage.frUsername,
        frFirstReviews: localStorage.feedbackkerFirstReviews + 1,

        srSecondImpressions: this.contribution.srSecondImpressions,
        srAdditions: this.contribution.srAdditions,
        srStandard: this.contribution.srValuable + this.contribution.srWellWritten + this.contribution.srTactful,

        srUsername: localStorage.atUsername,
        srSecondReviews: localStorage.feedbackkerSecondReviews + 1,

        srExists: this.srExists
      }
      this.$store.dispatch('feedbackkerSubmissions/contribute', contributionData)
    }
  },
  data: () => ({
    retrieved: {
      frTitle: localStorage.frTitle,
      frLink: localStorage.frLink,
      frSubmittingUserUsername: localStorage.frUsername,
      frAgeRating: localStorage.frAgeRating,
      frFan: localStorage.frFan,
      frTags: localStorage.frTags,
      frSubmissionNumber: localStorage.frSubmissionNumber,
      frFormat: localStorage.frFormat,
      frMedium: localStorage.frMedium,
      frFocus: localStorage.frFocus,
      frQuestions: localStorage.frQuestions,
      // blank  line
      srTitle: localStorage.srTitle,
      srLink: localStorage.srLink,
      srSubmittingUserUsername: localStorage.srUsername,
      srFirstReviewerUsername: localStorage.srFirstReviewerUsername,
      srFirstReviewerFirstReviewNumber: localStorage.srFirstReviewerFirstReviewNumber,
      srAgeRating: localStorage.srAgeRating,
      srFan: localStorage.srFan,
      srTags: localStorage.srTags,
      srSubmissionNumber: localStorage.srSubmissionNumber,
      srFormat: localStorage.srFormat,
      srMedium: localStorage.srMedium,
      srFocus: localStorage.srFocus,
      srQuestions: localStorage.srQuestions,
      srFirstImpressions: localStorage.srFirstImpressions,
      srAnswers: localStorage.srFirstAnswers,
      srQualms: localStorage.srFirstQualms,
      srSuggestions: localStorage.srFirstSuggestions,

      srExists: localStorage.getItem('srExists')
    },
    contribution: {
      frFirstImpressions: '',
      frAnswers: '',
      frQualms: '',
      frSuggestions: '',

      srTactful: '',
      srValuable: '',
      srWellWritten: '',

      srSecondImpressions: '',
      srAdditions: ''
    }
  })

}
</script>
<style scoped>
a{
  color: white;
}
#submitLabel{
  color: white;
}
</style>
