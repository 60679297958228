<template>
  <div>
    <GiveForm/>
  </div>
</template>

<script>
// @ is an alias to /src
import GiveForm from '@/components/GiveForm.vue'

export default {
  name: 'Give',
  components: {
    GiveForm
  }
}
</script>
