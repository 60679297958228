<template>
<div class="col-4 justify-content-center">
  <div class="form-group">
    <label for="InputEmail">Email address</label>
    <input type="email" class="form-control" id="InputEmail" v-model="user.email">
  </div>
  <button type="submit" @click="requestPasswordReset" class="btn btn-primary">Submit</button>
</div>
</template>

<script>
export default {
  name: 'PasswordResetRequestForm',
  props: {
    msg: String
  },
  data: () => ({
    user: {
      email: ''
    }
  }),
  methods: {
    requestPasswordReset () {
      const userData = {
        email: this.user.email
      }
      this.$store.dispatch('currentUser/passwordResetRequest', userData)
    }
  }
}
</script>
