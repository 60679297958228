<template>
  <div>
    <PasswordResetRequestForm/>
  </div>
</template>

<script>
// @ is an alias to /src
import PasswordResetRequestForm from '@/components/PasswordResetRequestForm.vue'

export default {
  name: 'PasswordResetRequest',
  components: {
    PasswordResetRequestForm
  }
}
</script>
